
import { Directive, ElementRef, OnInit, Renderer2, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { WindowService } from '../../services/window.service';
import { TranslationService } from '../translation/translation.service';

@Directive({
    selector: 'img',

})
export class SetImgAltDirective implements OnInit {
  private _el = inject(ElementRef);
  private _renderer = inject(Renderer2);
  private _title = inject(Title);
  private _window = inject(WindowService);
  private _translate = inject(TranslationService);


  ngOnInit() {
    const pathSegments = this._window?.nativeWindow?.location?.pathname?.split('/')?.filter(Boolean);
    let pageTitle = 'home-page';

    if (pathSegments.length > 1) {
      pageTitle = pathSegments[1];
    }

    const title = this._title?.getTitle();

    const pageKey = `t.${pageTitle}`;
    const newAlt = `${title || this._translate.translate(pageKey)} - Spinsamurai casino`;
    this._renderer.setAttribute(this._el?.nativeElement, 'alt', newAlt);
  }
}
